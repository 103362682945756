<template>
    <div class="grid gap-50">

        <ActionBar title="Payments">
            <Button text="Create Payment" size="small" @click="createPayment" />
        </ActionBar>


        <div class="grid row-20">

            <!-- <PageSearchInput v-model="_pageManager.query.search" placeholder="Search users..." /> -->

            <div class="page-filters">
                <div class="filter-item">
                    <div class="filter-item-name">Status</div>
                    <DropDownList v-model="_pageManager.query.status" placeholder="All" selectAttribute="value"
                        showAttribute="name"
                        :options="[{ value: null, name: 'All' }, { value: 'pending', name: 'Awaiting payment' }, { value: 'paid', name: 'Paid' }]" />
                </div>
            </div>

            <div class="item-list">
                <TableView :head="tableHead" :size="columnsSizes" v-if="(_pageManager.list.length > 0)"
                    :loading="isLoading(['GetPayments'])">
                    <TableRow :size="columnsSizes" :key="item._id" v-for="(item, key) of _pageManager.list">
                        <PaymentItem :item="item" @statusChange="(status) => { changePaymentStatus(item._id, status) }"/>
                    </TableRow>
                </TableView>

                <div v-else>
                    <div v-if="isLoading(['GetPayments'])" class="spinner-flex">
                        <Spinner />
                    </div>
                    <div v-else>
                        <NoDataMessage text="Nothing found" />
                    </div>
                </div>
            </div>

            <div class="pagination">
                <Pagination :manager="_pageManager" @pageChange="(page) => { _pageManager.query.page = page }" />
            </div>

        </div>

    </div>
</template>

<script>
import CreatePaymentModal from './components/CreatePayment'

import PaginationJS from '../../../mixins/Pagination'
import Pagination from '../../../mixins/Pagination/components/Pagination.vue'

import PaymentItem from './components/PaymentItem.vue'

export default {
    mixins: [PaginationJS],
    components: {
        Pagination,
        PaymentItem
    },
    data() {
        return {
            tableHead: [
                { name: 'Date' },
                { name: 'Referral' },
                { name: 'No. of loads' },
                { name: 'Total' },
                { name: 'Status' },
            ],
            columnsSizes: ['100px', 'minmax(100px, 1fr)', '100px', '100px', 'minmax(120px, 175px)'],
        }
    },
    watch: {
        "_pageManager.query.search": {
            deep: true,
            handler() {
                this._pageManager.query.page = 1;
            }
        },
        "_pageManager.query": {
            deep: true,
            handler() {
                this.getPayments();
            }
        }
    },
    methods: {
        changePaymentStatus(id, status) {
            this.ajax('HandlePaymentStatusChange', {
                url: `/referrals/payment/${id}/status/${status}`,
                method: 'put',
            }, (err, body) => { });
        },
        getPayments() {
            let query = new URLSearchParams(this._pageManager.query).toString();

            this.ajax('GetPayments', {
                url: '/referrals/payments?' + query,
                method: 'GET',
            }, (err, body) => {
                if (!err) {
                    this._pageManager.pages = body.pages;
                    this._pageManager.count = body.count;
                    this._pageManager.list = body.list;
                    this._pageManager.page = body.page;
                    this._pageManager.hasNextPage = body.hasNextPage;
                    this._pageManager.hasPrevPage = body.hasPrevPage;
                }
            });
        },
        createPayment() {
            const modal = this.$ShowModal({
                title: 'Create New Payment',
                component: CreatePaymentModal,
            });

            modal.onClose(() => {
                this.getPayments();
            });

        }
    },
    mounted() {
        this.getPayments()
    }
}
</script>

<style lang="scss" scoped>
.page-filters {
    display: flex;
    justify-content: flex-start;
    gap: 30px;
}
</style>